/* You can add global styles to this file, and also import other style files */
@import '~@swimlane/ngx-datatable/index.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
/*@import "../node_modules/angular-calendar/css/angular-calendar.css";*/

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

  .tooltip.show {
    opacity: 0.9;
  }

  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }

    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
  }

    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000;
    }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }

    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000;
    }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
  }

    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000;
    }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }

    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000;
    }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
  }

    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1);
  }

    .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
    .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
      border-width: 0.5rem 0.5rem 0;
    }

    .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
      bottom: 0;
      border-top-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
      bottom: 1px;
      border-top-color: #fff;
    }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }

    .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
    .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
      border-width: 0.5rem 0.5rem 0.5rem 0;
    }

    .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
      left: 0;
      border-right-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
      left: 1px;
      border-right-color: #fff;
    }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1);
  }

    .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
    .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
      border-width: 0 0.5rem 0.5rem 0.5rem;
    }

    .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
      top: 0;
      border-bottom-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
      top: 1px;
      border-bottom-color: #fff;
    }

  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
  }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }

    .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
    .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
      border-width: 0.5rem 0 0.5rem 0.5rem;
    }

    .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
      right: 0;
      border-left-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
      right: 1px;
      border-left-color: #fff;
    }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

  .popover-header:empty {
    display: none;
  }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}


.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      color: #000;
      text-decoration: none;
      opacity: .75;
    }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  opacity: 1;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: "";
  }

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

  .modal-backdrop.fade {
    opacity: 0;
  }

  .modal-backdrop.show {
    opacity: 0.5;
  }

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
  }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

  .modal-footer > :not(:first-child) {
    margin-left: .25rem;
  }

  .modal-footer > :not(:last-child) {
    margin-right: .25rem;
  }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }

    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2));
    }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}


.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  width: 100% !important;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.25rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }

  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
  }

  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent;
  }

.dropdown-menu.show {
  display: block;
  font-size: 1.2em;
}

.custom-select {
  font-size: 1em !important;
  height: auto !important;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}



.ngb-datepicker {
  width: 400px !important;
}

.ngb-dp-month {
  width: 100%;
}

.ngb-dp-weekdays,
.ngb-dp-week {
  justify-content: space-evenly;
}

* {
  outline: none;
}

.datatable-body-cell {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

  .datatable-body-cell:last-child {
    border: 0;
  }

.datatable-row-group {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

  .datatable-row-group:last-child {
    border-bottom: 0;
  }

.datatable-footer {
  border-top: 0 !important;
}

html, body {
  width: 100%;
  height: 100%;
  background-color: #f3f7fa !important;
  margin: 0;
  padding: 0;
}

.invalid-feedback {
  color: red;
  margin-top: 5px;
  margin-bottom: 5px;
}

.is-invalid {
  border: 1px solid red !important;
}

.body-content {
  padding-left: 100px;
}


.card {
  width: 100%;
  display: block;
  box-shadow: 0 8px 16px 0 rgba(40,40,90,.09), 0 3px 6px 0 rgba(0,0,0,.065);
  -webkit-box-shadow: 0 8px 16px 0 rgba(40,40,90,.09), 0 3px 6px 0 rgba(0,0,0,.065);
  background-color: #FFF;
  padding: 20px;
  color: #4a596a;
  margin-bottom: 20px;
}

.page-title {
  display: flex;
  margin-bottom: 20px;
  color: #4a596a;
  text-shadow: 0 0 1px #f3f7fa, 0 0 1px #f3f7fa, 0 0 1px #f3f7fa, 0 0 1px #f3f7fa, 0 0 1px #f3f7fa, 0 0 1px #f3f7fa, 0 0 1px #f3f7fa, 0 1px 2px rgba(0,0,0,.3), 0 3px 9px rgba(0,0,0,.1);
  align-items: center;
}

  .page-title h1 {
    flex-grow: 1;
  }

  .page-title .add {
    height: 50px;
    width: 50px;
    background-color: #5f8fdf;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    text-align: center;
    line-height: 52.5px;
    text-shadow: none;
    font-size: 18px;
    color: #FFF !important;
  }

    .page-title .add:hover {
      box-shadow: 0 8px 16px 0 rgba(40,40,90,.09), 0 3px 6px 0 rgba(0,0,0,.065);
      -webkit-box-shadow: 0 8px 16px 0 rgba(40,40,90,.09), 0 3px 6px 0 rgba(0,0,0,.065);
      cursor: pointer;
    }

.card-title h3 {
  margin: 0;
  margin-bottom: 10px;
}

  .card-title h3 i {
    margin-right: 20px;
    margin-bottom: 20px;
  }


.form-control {
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
}

.core-btn {
  background-color: #5f8fdf;
  padding: 10px;
  outline: 0;
  border: 0;
  color: #FFF;
}

  .core-btn i {
    margin-right: 10px;
  }

  .core-btn:hover {
    box-shadow: 0 8px 16px 0 rgba(40,40,90,.09), 0 3px 6px 0 rgba(0,0,0,.065);
    -webkit-box-shadow: 0 8px 16px 0 rgba(40,40,90,.09), 0 3px 6px 0 rgba(0,0,0,.065);
  }

.content-container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 95%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
  padding-bottom: 40px;
}

router-outlet ~ * {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
}

.column-picker {
}

.dropdown .keep-open {
  margin-bottom: 20px;
}

.column-picker li {
  border-bottom: 1px solid #4a596a;
  padding: 5px;
  color: #4a596a;
}

  .column-picker li:last-child {
    border-bottom: 0px;
  }

.edit-option {
  color: #1875d2;
  cursor: pointer;
}

  .edit-option i {
    margin-right: 10px;
  }

.complete-option {
  color: #56F175;
  cursor: pointer;
}

  .complete-option i {
    font-size: 1.5em;
    margin-right: 10px;
  }


.remove-option {
  color: red;
  cursor: pointer;
}

  .remove-option i {
    margin-right: 10px;
  }

.material-checkbox {
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
}

  .material-checkbox > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    position: absolute;
    z-index: -1;
    left: -15px;
    top: -15px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.42);
    outline: none;
    opacity: 0;
    transform: scale(1);
    -ms-transform: scale(0); /* Graceful degradation for IE */
    transition: opacity 0.5s, transform 0.5s;
  }

    .material-checkbox > input:checked {
      background-color: #2196f3;
    }

  .material-checkbox:active > input {
    opacity: 1;
    transform: scale(0);
    transition: opacity 0s, transform 0s;
  }

  .material-checkbox > input:disabled {
    opacity: 0;
  }

    .material-checkbox > input:disabled + span {
      cursor: initial;
    }

  .material-checkbox > span::before {
    content: "";
    display: inline-block;
    margin-right: 15px;
    border: solid 2px rgba(0, 0, 0, 0.42);
    border-radius: 2px;
    width: 14px;
    height: 14px;
    /*vertical-align: -4px;*/
    transition: border-color 0.5s, background-color 0.5s;
  }

  .material-checkbox > input:checked + span::before {
    border-color: #2196f3;
    background-color: #2196f3;
  }

  .material-checkbox > input:active + span::before {
    border-color: #2196f3;
  }

  .material-checkbox > input:checked:active + span::before {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.42);
  }

  .material-checkbox > input:disabled + span::before {
    border-color: rgba(0, 0, 0, 0.26);
  }

  .material-checkbox > input:checked:disabled + span::before {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.26);
  }

  .material-checkbox > span::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 10px;
    border: solid 2px transparent;
    border-left: none;
    border-top: none;
    transform: translate(5.5px, 1px) rotate(45deg);
    -ms-transform: translate(5.5px, 2px) rotate(45deg);
  }

  .material-checkbox > input:checked + span::after {
    border-color: #fff;
  }


.md-checkbox {
  position: relative;
  margin: 1em 0;
  text-align: left;
}

  .md-checkbox.md-checkbox-inline {
    display: inline-block;
  }

  .md-checkbox label {
    cursor: pointer;
    display: inline;
    line-height: 1.25em;
    vertical-align: top;
    clear: both;
    padding-left: 1px;
  }

    .md-checkbox label:not(:empty) {
      padding-left: 0.75em;
    }

    .md-checkbox label:before, .md-checkbox label:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }

    .md-checkbox label:before {
      width: 1.25em;
      height: 1.25em;
      background: #fff;
      border: 2px solid rgba(0, 0, 0, 0.54);
      border-radius: 0.125em;
      cursor: pointer;
      transition: background 0.3s;
    }

  .md-checkbox input[type=checkbox] {
    outline: 0;
    visibility: hidden;
    width: 1.25em;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;
  }

    .md-checkbox input[type=checkbox]:checked + label:before {
      background: #337ab7;
      border: none;
    }

    .md-checkbox input[type=checkbox]:checked + label:after {
      transform: translate(0.25em, 0.3365384615em) rotate(-45deg);
      width: 0.75em;
      height: 0.375em;
      border: 0.125em solid #fff;
      border-top-style: none;
      border-right-style: none;
    }

    .md-checkbox input[type=checkbox]:disabled + label:before {
      border-color: rgba(0, 0, 0, 0.26);
    }

    .md-checkbox input[type=checkbox]:disabled:checked + label:before {
      background: rgba(0, 0, 0, 0.26);
    }

.add-option {
  cursor: pointer;
  color: green;
}

.modal-add {
  padding: 20px;
}

.ui-calendar {
  width: 100% !important;
}

.ui-inputtext {
  border: 1px solid #ccc !important;
  border-radius: 0px !important;
  width: 100% !important;
}

.full-width {
  width: 100%;
}


.ngx-datatable {
  margin-bottom: 30px;
}

.reset-data {
  margin-left: 20px;
  border-radius: 0 !important;
}

.buffer-bottom {
  margin-bottom: 20px;
}

.buffer-top {
  margin-top: 20px;
}

.input-group-addon {
  position: relative;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.plus-icon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.btn-primary {
  color: #FFF;
  background-color: #5f8fdf;
  border-color: #5f8fdf;
}

  .btn-primary:hover {
    background-color: #5f8fdf;
    border-color: #5f8fdf;
  }

.btn-default {
  color: #333;
  background-color: #FFF;
  border-color: #CCC;
}

btn-top-margin {
  margin-top: 25px;
}

.no-shadow {
  box-shadow: 0 !important;
}

.select-picker {
  margin-top: 2px !important;
  border-radius: 0px !important;
}

.select-picker .ng-select-container {
  border-radius: 0px !important;
}

.rote-question {
  padding: 10px;
  background-color: #FFF;
  margin-bottom: 10px;
}


  .rote-question p {
      margin-bottom: 0px;
  }

.rote-responses {
    margin-top: 10px;
}

  .rote-responses label {
    margin-bottom: 0px;
    margin-right: 5px;
  }

  .rote-responses input {
    margin-right: 30px;
  }

    .rote-responses input[type=radio] {
      -ms-transform: scale(1.5); /* IE 9 */
      -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
      transform: scale(1.5);
      margin-left: 5px;
    }


.manifest-outbound {
  color: #65D2EB;
  margin-right: 20px;
}
.manifest-outbound-missing {
  color: #517DF5;
  margin-right: 20px;
}
.loler {
  color: #B353F8;
  margin-right: 20px;
}
.puwer {
  color: #EB63BB;
  margin-right: 20px;
}
.plant {
  color: green;
  margin-right: 20px;
}
.checklist {
  color: #476ADE;
  margin-right: 20px;
}
.rote {
  color: #DE4848;
  margin-right: 20px;
}
.pat {
  color: #F8A846;
  margin-right: 20px;
}
.project-report {
  color: #52DE57;
  margin-right: 20px;
}
.project-register {
  color: #F06464;
  margin-right: 20px;
}
